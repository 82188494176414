// src/queryClient.js
import { QueryClient } from '@tanstack/react-query';

// Create and export a QueryClient instance
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 100 * 60 * 1000,  // Data stays fresh for 5 minutes
      cacheTime: 100 * 60 * 1000, // Cache stays for 10 minutes
      retry: 50,                  // Retry failed queries twice
    },
  },
});
