import React from "react";
import { useCart } from "../context/CartContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const Cart = () => {
  const { state, dispatch } = useCart();

  const handleRemoveAll = (itemId) => {
    const item = state.items.find((item) => item.cartId === itemId);
    if (item) {
      dispatch({
        type: "REMOVE_FROM_CART",
        payload: { id: itemId, removeAll: true },
      });
      toast.error(`${item.name} has been removed from the cart.`);
    }
  };

  const handleIncrease = (itemId) => {
    dispatch({ type: "ADD_TO_CART", payload: { id: itemId } });
  };

  const handleDecrease = (itemId) => {
    dispatch({ type: "REMOVE_FROM_CART", payload: { id: itemId } });
  };

  const calculateItemTotal = (item) => {
    // Get the base unit price
    let totalPrice = parseFloat(item.unit_price) || 0;

    // Add the price of each variation (excluding the first one)
    item.variation.forEach((v, index) => {
      if (index > 0) {
        totalPrice += parseFloat(v.selectedVariationValues[0]?.price) || 0;
      }
    });
    totalPrice = totalPrice * item.quantity;
    return totalPrice;
  };

  return (
    <div className="p-6 min-h-screen bg-gray-100">
      <h2 className="text-3xl font-bold text-[#1c9d98] mb-6 text-center">
        Shopping Cart
      </h2>

      {state.items.length === 0 ? (
        <p className="text-gray-500 text-center text-lg">Your cart is empty.</p>
      ) : (
        <div className="flex flex-wrap gap-3 justify-center items-stretch">
          {state.items.map((item, index) => {
            const totalPrice = calculateItemTotal(item);

            return (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-2 w-full sm:w-auto flex flex-col transition-all transform hover:scale-105 hover:shadow-2xl duration-300 ease-in-out"
              >
                <div className="flex flex-row-reverse gap-6 items-center justify-center">
                  <div className="right">
                    <img
                      src={item.image_url || item.image}
                      alt={item.name}
                      className="w-[100px] h-[100px] object-cover"
                    />
                    <div className="flex gap-6 justify-center">
                      <button
                        className="mt-4 bg-red-500 w-full text-white px-3 py-2 hover:bg-red-700 transition-all duration-300 ease-in-out"
                        onClick={() => handleRemoveAll(item.cartId)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>

                  <div className="left">
                    <div className="flex-grow">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        {item.name}
                      </h3>
                      <span className="text-[#1c9d98] font-bold text-xl transition-all duration-300 ease-in-out">
                        QR {totalPrice.toFixed(2)}
                      </span>
                    </div>

                    {/* Display variations below the price as tags */}
                    <div className="mt-2">
                      {item.variation.map((v, index) => {
                        // Skip the first variation as it's the unit price
                        if (index === 0) return null;

                        // Combine multiple selected variation values into a single label
                        const variationValues = v.selectedVariationValues
                          .map((variationValue) => variationValue.variationValue)
                          .join(", "); // Join with a comma for multiple values

                        return (
                          <div key={index}>
                            {/* Display variation type and values in a single label */}
                            <span className="inline-block bg-[#1c9d98] text-white text-xs py-1 px-3 rounded-full">
                              <span className="mr-1">{v.variationType}:</span> {variationValues}
                            </span>
                          </div>
                        );
                      })}
                    </div>

                    <div className="flex items-center justify-center mt-2 gap-3">
                      <button
                        className="px-3 py-1 bg-red-500 text-white rounded-md transform hover:scale-105 transition-all duration-200 ease-in-out"
                        onClick={() => handleDecrease(item.cartId)}
                        disabled={item.quantity <= 1}
                      >
                        -
                      </button>
                      <span className="mx-4 text-lg font-bold transition-all duration-300 ease-in-out">
                        {item.quantity}
                      </span>
                      <button
                        className="px-3 py-1 bg-[#1c9d98] text-white rounded-md transform hover:scale-105 transition-all duration-200 ease-in-out"
                        onClick={() => handleIncrease(item.cartId)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {state.items.length === 0 ? (
        <div className="mt-6 text-center">
          <button
            className="bg-gray-400 text-white px-6 py-3 rounded-lg text-xl cursor-not-allowed"
            disabled
          >
            Proceed to Checkout
          </button>
        </div>
      ) : (
        <div className="mt-6 text-center">
          <Link
            to="/checkout"
            className="bg-[#1c9d98] text-white px-6 py-3 rounded-lg text-xl hover:bg-[#168c8b] transition-all duration-200 ease-in-out"
          >
            Proceed to Checkout
          </Link>
        </div>
      )}
    </div>
  );
};

export default Cart;
