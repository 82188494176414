// src/apis/CheckMobileNumber.js

import axios from 'axios';

const checkMobileNumber = async (mobileNumber) => {
    const url = `https://pandapanel.counterbill.com/connector/api/get-customer-name`;
    
    // Ensure the mobile number is properly encoded
    const encodedNumber = encodeURIComponent(mobileNumber);

    const token = sessionStorage.getItem('access_token'); // Retrieve the access token from session storage
// console.log(`${url}?mobile_number=${encodedNumber}`);

    try {
        const response = await axios.get(`${url}?mobile_number=${encodedNumber}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Include the token for authorization
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        });


        // Handle the response based on whether the user exists
        if (response.data.name) {
            // User exists, return the data
            return { exists: true, data: response.data };
        } else {
            // User does not exist
            return { exists: false, message: 'User not found' };
        }
    } catch (error) {
        console.error('Error checking mobile number:', error);
        if (error.response) {
            // If error is due to response status
            return { exists: false, message: error.response.data.message || 'Failed to check mobile number.' };
        }
        return { exists: false, message: 'Network error occurred. Please try again.' };
    }
};

export default checkMobileNumber;
