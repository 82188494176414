import axios from 'axios';
import getProductList from './getProductList';  // Import the product fetch function

const getTrending = async () => {
    const token = sessionStorage.getItem('access_token');
    
    try {
        const response = await axios.get(`https://pandapanel.counterbill.com/connector/api/reports/trending-products`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        // Extract IDs from the response data
        const ids = response.data.data.map(product => product.id);

        // Pass IDs and wrapCategory properly
        const data = await getProductList({ 
            ids: ids, 
            wrapCategory: "Most Trending Cakes" 
        });

        return data;  // Return the formatted products
        
    } catch (error) {
        console.error('Error fetching trending product IDs:', error);
        throw error;
    }
};

export default getTrending;
