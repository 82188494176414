import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faUser, faEllipsisH,faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomerProfileDropdown from './CustomerProfileDropdown';
import { useCart } from '../context/CartContext';

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('up');

  const { state } = useCart();

  useEffect(() => {
    setActiveLink(location.pathname);
    const customerId = sessionStorage.getItem('customer_id');
    const firstName = sessionStorage.getItem('name');

    if (customerId && firstName) {
      setIsLoggedIn(true);
      setUserName(firstName);
    } else {
      setIsLoggedIn(false);
    }
  }, [location.pathname]);

  // Scroll handling for showing/hiding the header
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Detect scroll direction
      if (currentScrollY > lastScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleLogout = () => {
    sessionStorage.removeItem('customer_id');
    sessionStorage.removeItem('name');
    setIsLoggedIn(false);
    setShowDropdown(false);
    window.location.href = "/";
  };

  return (
    <header 
      className={`flex gap-4 sticky top-0 z-50 items-center justify-between p-4 bg-[#1c9d98] text-white shadow-lg transition-transform duration-300 
                  ${scrollDirection === 'down' ? '-translate-y-full' : 'translate-y-0'}`} 
    >
      <div className="flex items-center">
        <img src="/pandaCake.png" alt="Logo" className="h-10" />
        <h1 className="ml-2 text-xl font-semibold whitespace-nowrap">Cake Shop</h1>
      </div>

      <div className="hidden md:flex space-x-4">
        {[
          { to: "/", icon: faHome, label: "Menu" },
          { to: "/cart", icon: faShoppingCart, label: "Cart" },
        ].map(({ to, icon, label }) => (
          <Link
            key={to}
            to={to}
            className={`flex items-center transition-all ease-in duration-300 transform 
                        ${activeLink === to ? 'bg-white text-[#1c9d98] rounded-md' : 'hover:bg-white hover:text-[#1c9d98] rounded-md'} 
                        p-2 w-24 h-12 justify-center`}
          >
            <FontAwesomeIcon icon={icon} className="mr-1 text-lg relative" />
            {label}
            {to === "/cart" && state.items?.length > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-1">
                {state.items?.length}
              </span>
            )}
          </Link>
        ))}
        {isLoggedIn ? (
          <div className="relative pt-2">
            <span 
              onClick={() => setShowDropdown(!showDropdown)}
              className="bg-white text-[#1c9d98] rounded-full w-8 h-8 flex items-center justify-center font-bold cursor-pointer"
            >
              {userName.charAt(0)}
            </span>
            {showDropdown && (
              <CustomerProfileDropdown userName={userName} onLogout={handleLogout} page={"header"} onClose={() => setShowDropdown(false)} />
            )}
          </div>
        ) : (
          <Link
            to="/login"
            className="flex items-center transition-all ease-in duration-300 transform hover:bg-white hover:text-[#1c9d98] rounded-md p-2 w-24 h-12 justify-center"
          >
            <FontAwesomeIcon icon={faUser} className="mr-1 text-lg" />
            Login
          </Link>
        )}
        <Link
          to="/more"
          className={`flex items-center transition-all ease-in duration-300 transform 
                      ${activeLink === "/more" ? 'bg-white text-[#1c9d98] rounded-md' : 'hover:bg-white hover:text-[#1c9d98] rounded-md'} 
                      p-2 w-24 h-12 justify-center`}
        >
          <FontAwesomeIcon icon={faEllipsisH} className="mr-1 text-lg" />
          More
        </Link>
        <Link
          to="/search"
          className={`flex items-center transition-all ease-in duration-300 transform 
                      ${activeLink === "/search" ? 'bg-white text-[#1c9d98] rounded-md' : 'hover:bg-white hover:text-[#1c9d98] rounded-md'} 
                      p-2 w-24 h-12 justify-center`}
        >
          <FontAwesomeIcon icon={faSearch} className="mr-1 text-lg" />
          Search
        </Link>
      </div>
    </header>
  );
};

export default Header;
