import axios from 'axios';

const getProductList = async ({ ids = [], category_id, wrapCategory, per_page, name }) => {
    const token = sessionStorage.getItem('access_token');
    
    try {
        const params = {};
        
        // Adding filters to the request params
        if (ids.length) params.product_id = ids.join(',');  // Handle multiple IDs
        if (category_id) params.category_id = category_id;   // Handle category filter
        if (per_page) params.per_page = per_page;   // Handle pagination
        if (name) params.name = name;  // Handle search query

        const response = await axios.get('https://pandapanel.counterbill.com/connector/api/product', {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params
        });

        const formattedProducts = {};

        // Iterate over the response data and format the products
        response.data.data.forEach((product,index) => {
            // If 'search' is present, return only required fields
            const productData = {
                id: product.id,
                name: product.name,
                description: product.product_description || '',
                image: product.image_url || '',
                default_price: product.product_variations[0]?.variations[0]?.default_sell_price || '0.00'
            };

            if (name) {
                // Return only the required fields for search
                formattedProducts[index] = productData;
            } else {
                // Handle the wrapCategory logic
                const targetCategory = wrapCategory || product.category?.name || 'Uncategorized';
                if (!formattedProducts[targetCategory]) {
                    formattedProducts[targetCategory] = [];
                }

                // If no search is provided, return the complete product with variations
                formattedProducts[targetCategory].push({
                    ...productData,
                    variations: product.product_variations.map(variation => ({
                        id: variation.id,
                        variation_type: variation.name,
                        choice_type: variation.choice_type,
                        variations: variation.variations.map(v => ({
                            id: v.id,
                            value: v.name,
                            price: v.default_sell_price,
                            image_url: v.media[0]?.display_url
                        }))
                    }))
                });
            }
        });

        return formattedProducts;

    } catch (error) {
        console.error('Error fetching product list:', error);
        throw error;
    }
};

export default getProductList;
