import axios from 'axios';

const getSells = async (contact_id) => {
    const token = sessionStorage.getItem('access_token');

    try {
        const response = await axios.get(`https://pandapanel.counterbill.com/connector/api/sell`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                per_page: -1,
                contact_id: contact_id
            }
        });

        const orders = response.data.data || [];

        // Map and extract required fields including product_id and variation_id
        const filteredOrders = orders.map(order => ({
            invoice_no: order.invoice_no,
            invoice_url: order.invoice_url,
            transaction_date: order.transaction_date,
            final_total: order.final_total,
            payment_status: order.payment_status,
            shipping_status: order.shipping_status
        }));

        console.log(filteredOrders); // Debugging output
        return filteredOrders;

    } catch (error) {
        console.error('Error fetching order history:', error);
        throw error;
    }
};

export default getSells;
