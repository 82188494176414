import React, { useState, useEffect } from 'react';
import getProductList from '../Apis/getProductList'; // Import your API call function
import CakeCard from '../components/CakeCard';
import { FaSearch } from 'react-icons/fa'; // Import FontAwesome search icon

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to handle API request based on the search term
  const fetchProducts = async () => {
    if (searchTerm.trim() === '') {
      setProducts([]); // Clear products if no search term
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await getProductList({ name: searchTerm });
      console.log(response);

      setProducts(Object.values(response)); // Convert object to array
    } catch (err) {
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  // Effect to call fetchProducts whenever the searchTerm changes
  useEffect(() => {
    fetchProducts();
  }, [searchTerm]);

  return (
    <div className="p-6 w-full mx-2 mx-auto">
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search for products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
      
          className="w-full px-4 py-3 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
        />
        <FaSearch
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          size={20}
        />
      </div>

      {loading && <div className="text-center text-gray-500">Loading...</div>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {Array.isArray(products) && products.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <CakeCard key={product.id} cake={product} />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-500">No products found</p>
      )}
    </div>
  );
};

export default Search;
