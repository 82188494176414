import React from 'react';
import { useQuery } from '@tanstack/react-query';
import CakeCard from '../components/CakeCard';
import { useState,useEffect } from 'react';
import getFavourite from '../Apis/getFavourite';
const CakeCardSkeleton = () => {
  return (
    
    <div className="flex gap-10 bg-white m-3 rounded-sm shadow-md p-4 animate-pulse">
    <div className="flex flex-col flex-1 pr-4 justify-center">
      {/* Skeleton Title */}
      <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
      {/* Skeleton Description */}
      <div className="h-4 bg-gray-200 rounded mb-2"></div>
      <div className="h-4 bg-gray-200 rounded mb-2"></div>
      {/* Skeleton Button */}
      <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
    </div>
    <div className="relative flex flex-col justify-center items-center">
      {/* Skeleton Image */}
      <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
    </div>
  </div>
  );
};
const UserFavorite = () => {
  const userId = sessionStorage.getItem('customer_id');
  const [categoryProducts, setCategoryProducts] = useState({});
  // Ensure queryKey is correctly formatted as an array
  const { data: favoriteProducts, isLoading, error } = useQuery({
    queryKey: userId ? ['userfavorites', userId] : ['favorites', null],  // Always pass an array
    queryFn: getFavourite,  // The query function
    enabled: !!userId,  // Only run query if userId exists
  });

   useEffect(() => {
      if (favoriteProducts) {
        setCategoryProducts(prevProducts => ({
          ...prevProducts,
          ...favoriteProducts
        }));
      }
    }, [favoriteProducts]);
  

  if (isLoading) {
    return (
      <div className='h-screen'>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 md:gap-6 ">
        {Array.from({ length: 6 }).map((_, index) => (
          <CakeCardSkeleton key={index} />
        ))}
      </div>
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center text-red-500">
        Error: {error.message}
      </p>
    );
  }
  console.log(favoriteProducts);  // Add this line for debugging

  return (
    <div className="flex-grow md:p-4 pt-2 overflow-y-auto min-h-screen">
     {Object.keys(categoryProducts).map(category => (
          <div key={category} className="mb-8">
            <h2 className="text-2xl font-bold text-center md:mb-4">{category}</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-0 md:gap-6">
              {categoryProducts[category].map(product => (
                <CakeCard key={product.id} cake={product} />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default UserFavorite;
