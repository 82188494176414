import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import { useNavigate } from 'react-router-dom';

const CakeCard = ({ cake, isLoading }) => {

  console.log(cake);
  
  const navigate = useNavigate();
  const sanitizedDescription = DOMPurify.sanitize(cake.description);
  const updatedPrice = useState(parseFloat(cake.default_price).toFixed(2));

  const OrderProduct = () => {
    navigate('/order?id=' + cake.id);
  };



  return (
    <div className="bg-white rounded-sm m-3 shadow-md md:shadow-lg hover:scale-105 hover:shadow-md md:hover:shadow-2xl transition-transform relative flex flex-row md:p-4 p-1 cursor-pointer" onClick={OrderProduct}>
      {/* Left Side: Title, Description, Add to Cart */}
      <div className="flex flex-col flex-1 pr-4 justify-center">
        <h3 className="text-lg font-semibold text-gray-900">{cake.name}</h3>

        <span
          className="text-gray-700 text-xs overflow-hidden overflow-ellipsis"
          style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        />

        {/* Action Buttons */}
        <div className="flex space-x-3 mt-3">
          <button
            className="flex items-center whitespace-nowrap border-2 border-[#1c9d98] text-[#1c9d98] px-4 py-2 rounded-lg hover:bg-[#1c9d98] hover:text-white transition duration-300 shadow-sm"
          >
            <FontAwesomeIcon icon={faShoppingCart} className="mr-2 text-sm" />
            {updatedPrice} QAR
          </button>
        </div>
      </div>

      {/* Right Side: Image with Like and Share Icons */}
      <div className="relative flex flex-col justify-center items-center">
        <img
          src={cake.image}
          alt={cake.name}
          className="w-24 lg:w-24 md:w-16 sm:w-12 h-auto object-cover rounded-lg"
          loading="lazy" // Enable lazy loading for images
        />
      </div>
    </div>
  );
};

export default CakeCard;
