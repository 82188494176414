import axios from 'axios';

export const getDeliveryAddress = async (userId) => {
  const token = sessionStorage.getItem('access_token');
console.log(userId);

  try {
    const response = await axios.get(
      `https://pandapanel.counterbill.com/connector/api/show-delivery-address/${userId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    return response.data;  // Return the delivery address data from the server
  } catch (error) {
    console.error('Error retrieving delivery address:', error);
    throw error;
  }
};
