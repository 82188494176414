// Apis/getCategoryList.js
import axios from 'axios';

const getCategoryList = async () => {
    const token = sessionStorage.getItem('access_token');
    try {
        const response = await axios.get(`https://pandapanel.counterbill.com/connector/api/taxonomy`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;  // Return category list
    } catch (error) {
        console.error('Error fetching category list:', error);
        throw error;
    }
};

export default getCategoryList;