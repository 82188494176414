import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// Skeleton Loader for Category Item
const CategoryItemSkeleton = () => {
  return (
    <li
      className="relative flex items-center justify-center text-center p-4 rounded-lg bg-gray-200 animate-pulse"
      style={{ maxWidth: '250px', maxHeight: '250px', minWidth: '120px', minHeight: '100px', overflow: 'hidden' }}
    >
      {/* Simulate Image Section */}
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-300"></div>

      {/* Simulate Text Overlay */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-gray-400 to-transparent">
        <div className="h-6 bg-gray-400 rounded w-3/4 mx-auto"></div>
      </div>
    </li>
  );
};

const CategoryList = ({ categories, onCategorySelect }) => {
  const [activeCategory, setActiveCategory] = useState('');
  const [loading, setLoading] = useState(true);  // Track loading state
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading delay for categories
    setTimeout(() => {
      setLoading(false);
    }, 1500); // Adjust time as necessary to simulate loading
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(category.name);
    onCategorySelect(category.id, category.name);  // Pass category ID and name to fetch products
    navigate("/");
  };

  return (
    <div className="pt-20 px-2">
      <h2 className="text-xl font-bold mb-6 text-center">Categories</h2>
      <ul className="grid grid-cols-2  gap-2 justify-center">
        {loading ? (
          // Show skeleton loaders if still loading
          Array.from({ length: 6 }).map((_, index) => <CategoryItemSkeleton key={index} />)
        ) : (
          categories.map((categoryData, index) => (
            <li
              key={index}
              onClick={() => handleCategoryClick(categoryData)}
              className={`relative cursor-pointer flex items-center justify-center text-center p-4 rounded-lg shadow-lg ${activeCategory === categoryData.name ? 'bg-[#1c9d98] text-white' : 'bg-white hover:shadow-md'}`}
              style={{ maxWidth: '250px', maxHeight: '250px', minWidth: '120px', minHeight: '100px', overflow: 'hidden' }}
            >
              {categoryData.image && (
                <img
                  src={categoryData.image}
                  alt={categoryData.name}
                  className="w-full h-full object-cover absolute top-0 left-0 right-0 bottom-0"
                  loading="lazy"  // Enable lazy loading for images
                />
              )}
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity"></div>
              <p className="absolute bottom-0 left-0 right-0 p-2 text-lg font-semibold text-white bg-gradient-to-t from-black to-transparent">
                {categoryData.name}
              </p>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default CategoryList;
