import React, { useEffect, useState } from 'react';
import getSells from '../Apis/getSells';

const OrderHistory = () => {
    const [orderHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const customerId = sessionStorage.getItem('customer_id');

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const data = await getSells(customerId);
                setOrderHistory(data);
            } catch (err) {
                setError('Failed to fetch order history.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderHistory();
    }, [customerId]);

    return (
        <div className="flex-grow md:p-6 p-4 bg-gray-50 min-h-screen">
            <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-lg p-6">
                <h1 className="text-3xl font-extrabold text-[#1c9d98] mb-8 text-center">Order History</h1>

                {loading ? (
                    <div className="text-center py-10">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-[#1c9d98] mx-auto"></div>
                        <p className="mt-4 text-gray-600">Fetching your orders...</p>
                    </div>
                ) : error ? (
                    <div className="text-center py-6">
                        <p className="text-red-500 font-medium">{error}</p>
                    </div>
                ) : orderHistory.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
                            <thead>
                                <tr className="bg-[#1c9d98] text-white uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 text-left">Invoice No</th>
                                    <th className="py-3 px-6 text-left">Date</th>
                                    <th className="py-3 px-6 text-left">Amount (QAR)</th>
                                    <th className="py-3 px-6 text-left">Payment Status</th>
                                    <th className="py-3 px-6 text-left">Shipping Status</th>
                                    <th className="py-3 px-6 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm">
                                {orderHistory.map((order, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-50">
                                        <td className="py-4 px-6 font-semibold">{order.invoice_no}</td>
                                        <td className="py-4 px-6">{new Date(order.transaction_date).toLocaleDateString()}</td>
                                        <td className="py-4 px-6 font-medium text-[#1c9d98]">
                                        QAR {parseFloat(order.final_total).toFixed(2)}
                                        </td>
                                        <td className="py-4 px-6">
                                            <span className={`px-3 py-1 rounded-full text-sm font-medium ${order.payment_status === 'Paid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                                                {order.payment_status}
                                            </span>
                                        </td>
                                        <td className="py-4 px-6">
                                            <button className={`px-4 py-2 rounded-full text-white font-semibold transition ${order.shipping_status === 'Shipped' ? 'bg-green-500 animate-pulse' : 'bg-gray-500'}`}>
                                                {order.shipping_status || 'Pending'}
                                            </button>
                                        </td>
                                        <td className="py-4 px-6">
                                            <a href={order.invoice_url} target="_blank" rel="noopener noreferrer"
                                                className="text-[#1c9d98] hover:underline font-medium">
                                                View Invoice
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="text-center py-12">
                        <p className="text-gray-500">You haven't placed any orders yet.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderHistory;
