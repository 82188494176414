import axios from 'axios';

export const storeDeliveryAddress = async (userId, deliveryAddress) => {
  const token = sessionStorage.getItem('access_token'); // Retrieve the access token
      if (deliveryAddress.length === 0) {
        deliveryAddress="";
      }
  try {
    const response = await axios.post(
      `https://pandapanel.counterbill.com/connector/api/store-delivery-address/${userId}`,
      {
        delivery_address: deliveryAddress,  // Send the delivery address in the request body
      },
      {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the request header
          'Content-Type': 'application/json',
        },
      }
    );


    return response.data;  // Return the response data from the server
  } catch (error) {
    console.error('Error storing delivery address:', error);
    throw error;
  }
};
