import React, { useState } from "react";
import { useCart } from "../context/CartContext";
import { toast } from "react-toastify";
import { storeSells } from "../Apis/StoreSells";
import html2canvas from "html2canvas"; // Import html2canvas
import dayjs from "dayjs";



const Checkout = () => {
  const [currentStep, setCurrentStep] = useState(1); // Keep track of the current step
  const [deliveryType, setDeliveryType] = useState("3hours");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const { state, dispatch } = useCart();
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const timeSlots = [
    { label: "08:00 AM - 11:00 AM", value: "08:00 AM - 11:00 AM" },
    { label: "11:00 AM - 02:00 PM", value: "11:00 AM - 02:00 PM" },
    { label: "02:00 PM - 05:00 PM", value: "02:00 PM - 05:00 PM" },
    { label: "05:00 PM - 08:00 PM", value: "05:00 PM - 08:00 PM" },
    { label: "08:00 PM - 11:00 PM", value: "08:00 PM - 11:00 PM" },
  ];
  

  // Function to move to the next step
  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Function to go back to the previous step
  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  // Generate the next 7 days (including today)
  const generateDates = () => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = dayjs().add(i, "day");
      dates.push({
        label: date.format("ddd"),
        date: date.format("YYYY-MM-DD"),
        display: date.format("MMM DD"),
      });
    }
    return dates;
  };
  const dates = generateDates();

  // Filter time slots for today
  const filteredTimeSlots = () => {
    const currentHour = dayjs().hour();
    if (selectedDate === dayjs().format("YYYY-MM-DD")) {
      return timeSlots.filter((slot) => {
        const startHour = parseInt(slot.value.split("-")[0], 10);
        return startHour > currentHour;
      });
    }
    return timeSlots;
  };

  const calculateItemTotal = (item) => {
    let basePrice = Number(item.unit_price) * item.quantity;
    let variationCost = 0;

    item.variation?.forEach((variation) => {
      if (variation.variationType !== "Default") {
        variationCost +=
          Number(variation.selectedVariationValues[0]?.price || 0) *
          item.quantity;
      }
    });

    return basePrice + variationCost;
  };

  const calculateOverallTotal = () => {
    return state.items.reduce(
      (total, item) => total + calculateItemTotal(item),
      0
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Calculate total and other necessary values
    const finalTotal = calculateOverallTotal();
    const products = state.items.flatMap((item, index) =>
      item.variation.flatMap((variation) =>
        variation.selectedVariationValues.map(
          (selectedVariationValue, variationIndex) => ({
            product_id: item.id,
            product_type: "variable",
            unit_price: parseFloat(selectedVariationValue.price || 0).toFixed(
              2
            ), // Price for the individual variation
            unit_price_inc_tax: parseFloat(
              selectedVariationValue.price || 0
            ).toFixed(2), // Price with tax for the individual variation
            line_discount_type: "fixed",
            line_discount_amount: 0.0,
            item_tax: 0.0,
            tax_id: null,
            sell_line_note: item.instructions || null,
            variation_id: selectedVariationValue.id || variation.id, // Use the selected variation ID
            enable_stock: true,
            quantity: item.quantity, // Quantity of the item
            product_unit_id: item.unit_id,
            base_unit_multiplier: 1,
          })
        )
      )
    );

    // Add payments - modify this based on the payment methods used
    const payments = [
      {
        amount: finalTotal,
        method: "cash", // Example payment method, modify as needed
        account_id: 1, // Example account ID, modify as needed
        note: "Payment for order", // Example note, modify as needed
      },
    ];

    // Preparing the full sell data object
    const sellData = {
      sells: {
        change_return: 0.0,
        contact_id: sessionStorage.getItem("customer_id"), // Customer ID from session
        discount_amount: 0.0,
        discount_type: "fixed",
        final_total: finalTotal,
        location_id: 3, // Modify based on actual location ID
        shipping_details: deliveryAddress,
        shipping_address: deliveryAddress,
        shipping_status: "ordered", // Example shipping status
        delivered_to: "Customer Name", // Modify based on actual customer name
        shipping_charges: 0, // Example shipping charges, modify as needed
        packing_charge: 0, // Example packing charges, modify as needed
        exchange_rate: 1, // Example exchange rate, modify as needed
        selling_price_group_id: 1, // Example selling price group ID, modify as needed
        pay_term_number: 3, // Example payment terms, modify as needed
        pay_term_type: "months", // Example payment term type, modify as needed
        is_suspend: 0, // Example suspend status
        is_recurring: 0, // Example recurring status
        recur_interval: 1, // Example recurring interval
        recur_interval_type: "months", // Example recurring interval type
        subscription_repeat_on: 15, // Example subscription repeat day, modify as needed
        subscription_no: "SUB12345", // Example subscription number
        recur_repetitions: 12, // Example total repetitions
        rp_redeemed: 0, // Example reward points redeemed
        rp_redeemed_amount: 0.0, // Example reward points redeemed amount
        types_of_service_id: 1, // Example service type ID, modify as needed
        round_off_amount: 0.0, // Example round-off amount, modify as needed
        table_id: 1, // Example table ID for restaurant, modify as needed
        status: "final",
        is_credit_sale: 1,
        tax_rate_id: "0",
        service_staff_id: 1, // Example service staff ID, modify as needed
        shipping_custom_field_1: deliveryType === "scheduled"
      ? { deliveryType, selectedDate, selectedTimeSlot }
      : { deliveryType },
        products, // Adding the products to the sell data
        payments, // Adding the payments to the sell data
      },
    };

    try {
      const response = await storeSells(sellData);
      console.log(response);
      if (response.success && response.receipt.is_enabled) {
        // Create a container to hold the HTML content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = response.receipt.html_content;

        // Apply Tailwind CSS classes to improve the layout of the content
        tempDiv.classList.add(
          "bg-white",
          "p-6",
          "rounded-lg",
          "shadow-lg",
          "max-w-4xl",
          "mx-auto",
          "my-10"
        );

        // Add additional Tailwind styling
        const addTailwindStyles = () => {
          // Add Tailwind CSS classes to specific sections of the content.
          // For example, improving the table design:
          const tables = tempDiv.querySelectorAll("table");
          tables.forEach((table) => {
            table.classList.add(
              "w-full",
              "table-auto",
              "border-separate",
              "border-spacing-2",
              "border-gray-300"
            );
          });

          // Modify text sections
          const textSections = tempDiv.querySelectorAll("p, h2, h3");
          textSections.forEach((section) => {
            if (section.tagName === "H2") {
              section.classList.add(
                "text-3xl",
                "font-semibold",
                "text-gray-800",
                "text-center"
              );
            }
            if (section.tagName === "H3") {
              section.classList.add(
                "text-xl",
                "font-semibold",
                "text-gray-800",
                "text-center"
              );
            }
            if (section.tagName === "P") {
              section.classList.add("text-gray-600", "text-sm", "mt-2");
            }
          });
        };

        addTailwindStyles(); // Apply Tailwind styles to the temporary content.

        // Append the content temporarily to the document body (it won't be visible to the user)
        document.body.appendChild(tempDiv);

        // Use html2canvas to capture the content as an image
        html2canvas(tempDiv).then((canvas) => {
          // Convert the canvas to an image (PNG format)
          const imgData = canvas.toDataURL("image/png");

          // Create a link to download the image
          const link = document.createElement("a");
          link.href = imgData;
          link.download = "order_invoice.png"; // Set the name of the image
          link.click(); // Trigger the download

          // Remove the temporary content after generating the image
          document.body.removeChild(tempDiv);

          // Dispatch an action and show toast
          toast.success(
            "Order placed successfully! Your receipt has been downloaded."
          );
          dispatch({ type: "CLEAR_CART" });

          // Optionally redirect after 2 seconds (if needed)
          setTimeout(() => {
            window.location.href = "/orderhistory"; // Uncomment if you want to redirect
          }, 2000);
        });
      } else {
        toast.error(response.message || "Order submission failed.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error placing order.");
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = (e) => {
    // If you want to stop form submission when the button is clicked, you can call e.preventDefault()
    e.preventDefault();

    // Perform any other logic if needed (e.g., logging or validation)
    console.log("Button clicked!");

    // You can also call the form submission logic if needed
    handleSubmit(e); // Calls the handleSubmit function from earlier
  };

  const handleAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
  };

  return (
    <div className="min-h-screen bg-white flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md space-y-6">
        <h2 className="text-3xl font-bold text-center">Checkout</h2>
  
        {/* Step 2: Delivery Time */}
        {currentStep === 1 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4 text-[#1c9d98]">
              Select Delivery Time
            </h3>
            <div className="mb-6">
              <label className="flex items-center space-x-3 mb-3">
                <input
                  type="radio"
                  value="3hours"
                  checked={deliveryType === "3hours"}
                  onChange={() => setDeliveryType("3hours")}
                />
                <span>In 3 hours</span>
              </label>
              <label className="flex items-center space-x-3">
                <input
                  type="radio"
                  value="schedule"
                  checked={deliveryType === "schedule"}
                  onChange={() => setDeliveryType("schedule")}
                />
                <span>Scheduled Order</span>
              </label>
            </div>
  
            {/* Date and Time Slot Section */}
            {deliveryType === "schedule" && (
              <>
                <div className="overflow-x-auto whitespace-nowrap pb-2">
                  <div className="flex space-x-4">
                    {dates.map((d) => (
                      <div
                        key={d.date}
                        className={`p-4 text-center cursor-pointer rounded-lg ${
                          selectedDate === d.date
                            ? "bg-[#1c9d98] text-white"
                            : "bg-gray-200"
                        }`}
                        onClick={() => setSelectedDate(d.date)}
                      >
                        <div className="text-lg">{d.display}</div>
                        <div className="text-sm">
                          {d.date === dayjs().format("YYYY-MM-DD")
                            ? "Today"
                            : d.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-6 space-y-4">
                  {filteredTimeSlots().map((slot) => (
                    <label
                      key={slot.value}
                      className="flex items-center space-x-3"
                    >
                      <input
                        type="radio"
                        value={slot.value}
                        checked={selectedTimeSlot === slot.value}
                        onChange={() => setSelectedTimeSlot(slot.value)}
                      />
                      <span>{slot.label}</span>
                    </label>
                  ))}
                </div>
              </>
            )}
          </div>
        )}
  
        {/* Step 3: Delivery Address */}
        {currentStep === 2 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4 text-[#1c9d98]">
              Delivery Address
            </h3>
            <textarea
              value={deliveryAddress}
              onChange={handleAddressChange}
              required
              className="w-full border rounded-lg p-2"
              placeholder="Delivery Address"
            />
          </div>
        )}
  
        {/* Step 4: Payment Mode + Order Summary */}
        {currentStep === 3 && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-4 text-[#1c9d98]">
              Payment Mode
            </h3>
            <div className="bg-[#1c9d98] rounded-sm shadow-sm flex flex-col justify-center items-center gap-2">
              <h4 className="text-white font-bold p-2">Cash On Delivery</h4>
            </div>
  
            {/* Order Summary (Moved from Step 1 to Step 4) */}
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4 text-[#1c9d98]">
                Order Summary
              </h3>
              {state.items.length > 0 ? (
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-[#1c9d98] text-white">
                    <tr>
                      <th className="px-4 py-2 text-left font-medium">
                        Description
                      </th>
                      <th className="px-4 py-2 text-left font-medium">Price</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {state.items.map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td className="px-4 py-2 font-medium text-gray-900">
                            {item.name}
                          </td>
                          <td className="px-4 py-2 text-[#1c9d98] font-semibold">
                            QR {parseFloat(item.unit_price).toFixed(2)}
                          </td>
                        </tr>
                        {item.variation
                          ?.filter((v) => v.variationType !== "Default")
                          .map((v, i) => {
                            const variationValues = v.selectedVariationValues
                              .map(
                                (variationValue) => variationValue.variationValue
                              )
                              .join(", ");
                            const totalVariationPrice =
                              v.selectedVariationValues.reduce(
                                (total, variationValue) =>
                                  total + (parseFloat(variationValue.price) || 0),
                                0
                              );
                            return (
                              <tr key={i}>
                                <td className="px-4 py-2 text-sm text-gray-600">
                                  + {v.variationType}: {variationValues}
                                </td>
                                <td className="px-4 py-2 text-sm text-[#1c9d98]">
                                  QR {totalVariationPrice.toFixed(2)}
                                </td>
                              </tr>
                            );
                          })}
                        <tr>
                          <td className="px-4 py-2 text-sm text-gray-600">
                            * Quantity
                          </td>
                          <td className="px-4 py-2 text-sm text-[#1c9d98]">
                            {item.quantity}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-center text-gray-600">Your cart is empty.</p>
              )}
              <div className="mt-4 font-bold flex justify-between bg-[#e0f2f1] p-4 rounded-lg">
                <p className="text-lg text-gray-800">Total Order Price</p>
                <p className="text-lg font-semibold text-[#1c9d98]">
                  QR {parseFloat(calculateOverallTotal()).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        )}
  
        {/* Step Navigation */}
        <div className="flex justify-between">
          <button
            onClick={prevStep}
            disabled={currentStep === 2}
            className="bg-gray-300 text-gray-700 p-2 rounded-lg"
          >
            Back
          </button>
          <span>Step {currentStep} of 4</span>
          <button
            onClick={nextStep}
            className="bg-gray-300 text-gray-700 p-2 rounded-lg"
          >
            {currentStep === 4 ? "Place Order" : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
  
};

export default Checkout;
