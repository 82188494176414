import axios from 'axios';
import getProductList from './getProductList';

const getFavourite = async ({ queryKey }) => {
    console.log(queryKey);
    
  // Ensure the queryKey is an array and destructure correctly
  const [title, contact_id] = queryKey;  // Extract contact_id from queryKey
  console.log(title);
  
  const token = sessionStorage.getItem('access_token');
  console.log(contact_id);

  try {
    const response = await axios.get(`https://pandapanel.counterbill.com/connector/api/contactapi`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        per_page: -1,
        contact_ids: contact_id,  // Pass contact_id as a query parameter
      },
    });


    // Extract IDs from the response data
    const ids = response.data.data
      .map((product) => (product.custom_field1 ? JSON.parse(product.custom_field1) : null))
      .filter((item) => item !== null && item.length > 0)
      .flat()
      .map((item) => parseInt(item, 10));

    // Count occurrences of each ID
    const frequencyMap = ids.reduce((acc, id) => {
      acc[id] = (acc[id] || 0) + 1;
      return acc;
    }, {});

    // Sort IDs by frequency
    const sortedIds = ids
      .sort((a, b) => frequencyMap[b] - frequencyMap[a])
      .filter((value, index, self) => self.indexOf(value) === index);

    let data;

    
console.log("contact_id:" + contact_id);



     
    if (queryKey.length === 1  ) {
      // Get the top 5 IDs if no contact_id is provided
      const top5Ids = sortedIds.slice(0, 5);
      console.log(top5Ids);
      data = await getProductList({
        ids: top5Ids,
        wrapCategory: 'Most Favourite Cakes',  // Wrap category for the most frequent favorites
      });
    } else {
      // Get all sorted IDs if contact_id is provided
      data = await getProductList({
        ids: sortedIds,
        wrapCategory: 'Your Favourite Cakes',  // Wrap category for all favorites
      });

  
      
    }

    return data;
  } catch (error) {
    console.error('Error fetching trending product IDs:', error);
    throw error;
  }
};

export default getFavourite;
