import axios from 'axios';

const storeMobileNumber = async (mobileNumber, name) => {
    const url = 'https://pandapanel.counterbill.com/connector/api/contactapi'; // API endpoint

    const token = sessionStorage.getItem('access_token'); // Retrieve the access token

    try {
        // Create a FormData object and append the required fields
        const formData = new FormData();
        formData.append('mobile', mobileNumber); // Corrected field name
        formData.append('first_name', name); // Corrected field name
        formData.append('type', 'customer');

        const response = await axios.post(
            url,
            formData, // Use the FormData object
            {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token
                    'Accept': 'application/json', // Expect JSON response
                    // 'Content-Type' is not necessary when using FormData, axios sets it automatically
                },
            }
        );

      

        return response.data;
    } catch (error) {
        console.error('Error storing mobile number:', error);
        if (error.response) {
            console.error('Error response data:', error.response.data);
        }
        throw error; // Rethrow the error to handle it in the calling component
    }
};

export default storeMobileNumber;
