import axios from 'axios';

const getContact = async (params) => {
    let url = `https://pandapanel.counterbill.com/connector/api/contactapi`;

    const token = sessionStorage.getItem('access_token'); // Retrieve the access token from session storage
    const { contact_id, ...resdata } = params; // Destructure contact_id and other data from params

    
    console.log('Request Data (resdata):', resdata);

    try {
        let response;

        // If resdata is available, convert it to FormData
        if (Object.keys(resdata).length > 0) {
            // Create a new FormData instance
            const formData = new FormData();

            // Append each key and value from resdata to the FormData
            for (const key in resdata) {
                if (resdata.hasOwnProperty(key)) {
                
                    formData.append(key, resdata[key]);
                }
            }

      

url=url+"/"+contact_id;
            // Send POST request with FormData
            response = await axios.put(url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token for authorization
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'// is automatically set when using FormData
                },
            });

           
        } else {
            // Otherwise, make a GET request to fetch contact data
            response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`, // Include the token for authorization
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                params: { contact_id }, // Pass contact_id as a query parameter
            });
        }

        // Handle the response based on whether the user exists
        if (response.data.data) {
            return { exists: true, data: response.data.data };
        } else {
            return { exists: false, message: 'User not found' };
        }
    } catch (error) {
        console.error('Error processing request:', error);
        if (error.response) {
            console.error('Error Response:', error.response.data);
            return { exists: false, message: error.response.data.message || 'Failed to check mobile number.' };
        }
        return { exists: false, message: 'Network error occurred. Please try again.' };
    }
};

export default getContact;
