import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import getContact from '../Apis/getContact'; // Import the API function

const formFields = [
    { label: 'Name', name: 'name', type: 'text' },
    { label: 'Email', name: 'email', type: 'email' },
    { label: 'Address Line 1', name: 'address_line_1', type: 'text' },
    { label: 'Address Line 2', name: 'address_line_2', type: 'text' },
    { label: 'City', name: 'city', type: 'text' },
    { label: 'State', name: 'state', type: 'text' },
    { label: 'Country', name: 'country', type: 'text' },
    { label: 'Zip Code', name: 'zip_code', type: 'text' },
];

function Info() {
    const customerId = sessionStorage.getItem('customer_id'); // Retrieve the customer_id from session storage

    // Fetch data for the contact
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['contact', customerId],
        queryFn: () => getContact({ contact_id: customerId }),
    });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        zip_code: '',
    });

    // Initialize form with existing data
    useEffect(() => {
        if (data && data.exists) {
            const contact = data.data[0];
            setFormData({
                name: contact.name || '',
                email: contact.email || '',
                mobile: contact.mobile || '',
                address_line_1: contact.address_line_1 || '',
                address_line_2: contact.address_line_2 || '',
                city: contact.city || '',
                state: contact.state || '',
                country: contact.country || '',
                zip_code: contact.zip_code || '',
            });
        }
    }, [data]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const mutation = useMutation({
        mutationFn: (updatedData) => getContact({ contact_id: customerId, ...updatedData }),
        onSuccess: (data) => {
            // Handle success (e.g., show a success message or redirect)
            console.log('Contact updated successfully:', data);
        },
        onError: (error) => {
            // Handle error
            console.error('Error updating contact:', error);
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        const {...formDataWithoutMobile } = formData; // Exclude mobile number from the submission
       // Add first_name with the value of name
    const formDataWithFirstName = {
        ...formDataWithoutMobile,
        first_name: formData.name, // Set first_name to the value of name
        type:"customer"
    };

    // Call mutation to send formDataWithFirstName for submission
    mutation.mutate(formDataWithFirstName);
    };

    // Loading state
    if (isLoading) {
        return (
            <div className="max-w-full m-2 sm:w-[50rem] mx-auto p-6 bg-white shadow-lg rounded-lg">
                <h1 className="text-3xl font-extrabold text-center text-[#1c9d98] mb-6">Personal Info</h1>

                <form>
                    <div className="mb-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
                        {/* Skeleton Loader for Form Fields */}
                        {formFields.map((field, index) => (
                            <div key={index}>
                                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div> {/* Label Skeleton */}
                                <div className="h-12 bg-gray-200 rounded"></div> {/* Input Skeleton */}
                            </div>
                        ))}
                        {/* Skeleton Loader for Mobile */}
                        <div>
                            <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div> {/* Label Skeleton */}
                            <div className="h-12 bg-gray-200 rounded cursor-not-allowed"></div> {/* Input Skeleton */}
                        </div>
                    </div>

                    <div className="h-12 bg-[#1c9d98] rounded-md cursor-not-allowed"></div> {/* Button Skeleton */}
                </form>
            </div>
        );
    }

    // Error state
    if (isError) {
        return <div>Error: {error.message}</div>;
    }

    // Success state
    if (data) {
        return (
            <div className="max-w-full m-2 sm:w-[50rem] mx-auto p-6 bg-white shadow-lg rounded-lg">
                <h1 className="text-3xl font-extrabold text-center text-[#1c9d98] mb-6">Personal Info</h1>

                <form onSubmit={handleSubmit}>
                    <div className='mb-2 grid grid-cols-1 sm:grid-cols-2 gap-4'>
                        {formFields.map((field, index) => (
                            <div key={index}>
                                <label className="block text-sm font-medium text-gray-700">{field.label}</label>
                                <input
                                    type={field.type}
                                    name={field.name}
                                    value={formData[field.name]}
                                    onChange={handleChange}
                                    className="w-full p-3 mt-2 border border-gray-300 rounded-lg"
                                    placeholder={`Enter your ${field.label.toLowerCase()}`}
                                />
                            </div>
                        ))}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Mobile</label>
                            <input
                                type="text"
                                name="mobile"
                                value={formData.mobile}
                                className="w-full p-3 mt-2 bg-gray-200 border border-gray-300 rounded-lg cursor-not-allowed"
                                disabled
                                placeholder="Your mobile number"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="w-full py-3 bg-[#1c9d98] text-white rounded-md hover:bg-[#169f91]"
                    >
                        Save Changes
                    </button>
                </form>
            </div>
        );
    }

    return null;
}

export default Info;
