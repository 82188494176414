import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import { Navigation, Pagination,Autoplay ,EffectFade } from 'swiper/modules';
import { useCart } from '../context/CartContext';
import DOMPurify from 'dompurify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { storeFavProduct } from '../Apis/StoreFavProduct';
import { useFavorites } from '../context/FavoritesContext';
import getProductList from '../Apis/getProductList';  // Import the product fetch function

const ProductOrder = () => {
    const navigate = useNavigate();
    const [cake, setCake] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
   // Keep cake data as null initially
    const [sliderImages, setSliderImages] = useState([]);
    // const [currentSlide, setCurrentSlide] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [instructions, setInstructions] = useState('');
    const [selectedVariations, setSelectedVariations] = useState({});
    const [updatedPrice, setUpdatedPrice] = useState(0); // Set a default price initially
    const [totalPrice, setTotalPrice] = useState(updatedPrice);
    const [showShareTooltip, setShowShareTooltip] = useState(false);
    const [showLikeTooltip, setShowLikeTooltip] = useState(false);
  
    const { dispatch } = useCart();
    const { favorites, setFavorites } = useFavorites();
    const userId = sessionStorage.getItem('customer_id');
    const [isFavorite, setIsFavorite] = useState(false);
    const [sanitizedDescription, setSanitizedDescription] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');  // Get the 'id' parameter value
    
    
  
    useEffect(() => {
        const fetchProduct = () => {
    
        
          if (id) {
            setIsLoading(true);  // Start loading
            // Start fetching product data
            getProductList({ ids: [id] }).then((productData) => {
              // Set the fetched data to state after fetching
                 var flattenedCakes = Object.values(productData).flat();  // This removes the categories and flattens the array
                 flattenedCakes=flattenedCakes[0];
   
              setCake(flattenedCakes);
        console.log(flattenedCakes);
          
              setSliderImages([flattenedCakes.image]);
              // You can continue doing other logic here without waiting for the state update
             
            
              setIsLoading(false);  // End loading
            }).catch((error) => {
              console.error('Error fetching product:', error);
              setIsLoading(false);  // End loading
            });
          } else {
         
            setIsLoading(false);  // End loading
          }
        };
    
        fetchProduct(); // Call the fetch function when the component mounts
      }, [id]); // Empty dependency array to call the effect only once when the component mounts
    
   

     // Effect for updating `isFavorite` state
  useEffect(() => {
    if (cake) {
      setIsFavorite(favorites.includes(cake.id.toString()));
    }
  }, [cake, favorites]); // Update whenever cake or favorites change

  // Effect for updating `sanitizedDescription` state
  useEffect(() => {
    if (cake) {
      setSanitizedDescription(DOMPurify.sanitize(cake.description || ''));
    }
  }, [cake,id]); // Update whenever cake data changes

  useEffect(() => {
    const swiperElement = document.querySelector('.swiper');
    
    if (swiperElement && swiperElement.swiper) {
        const swiper = swiperElement.swiper;
        swiper.slideNext();  // Slide to the next slide immediately
    }
}, [sliderImages]);  // Trigger when sliderImages change (force re-render)

    useEffect(() => {
        if (cake?.variations?.length > 0) {
            const firstVariation = cake.variations[0]?.variations[0];
            if (firstVariation && !selectedVariations[cake.variations[0].variation_type]) {
                setSelectedVariations((prev) => ({
                    ...prev,
                    [cake.variations[0].variation_type]: firstVariation.id,
                }));
                setUpdatedPrice(parseFloat(firstVariation.price));
            }
        }
    }, [cake, selectedVariations]);

    useEffect(() => {
        setTotalPrice(updatedPrice * quantity);
    }, [quantity, updatedPrice,id]);
    
    const handleVariationChange = (variationType, optionId, price, imageUrl, choiceType) => {
      setSelectedVariations((prev) => {
          let updatedVariations = { ...prev };
  
          if (choiceType === 2) {
              // Multiple Choice – Toggle Selection
              const selectedOptions = prev[variationType] || [];
              if (selectedOptions.includes(optionId)) {
                  updatedVariations[variationType] = selectedOptions.filter(id => id !== optionId);
              } else {
                  updatedVariations[variationType] = [...selectedOptions, optionId];
              }
          } else {
              // Single Choice (Optional or Required)
              updatedVariations[variationType] = optionId;
          }
  
          // Recalculate Total Price Based on Selected Variations
          const selectedOptions = Object.entries(updatedVariations).flatMap(([type, ids]) => {
              const selectedVar = cake.variations.find(v => v.variation_type === type);
              return Array.isArray(ids)
                  ? selectedVar.variations.filter(v => ids.includes(v.id))
                  : [selectedVar.variations.find(v => v.id === ids)];
          });
  
          const totalVariationPrice = selectedOptions.reduce(
              (acc, option) => acc + parseFloat(option?.price || 0),
              0
          );
  
          setUpdatedPrice(totalVariationPrice);
  
          // Update Slider Images Based on Selection
          const newSliderImages = [cake.image];
          selectedOptions.forEach(option => {
              if (option?.image_url) {
                  newSliderImages.push(option.image_url);
              }
          });
  
          setSliderImages(newSliderImages);
          return updatedVariations;
      });
  };
  

    const handleQuantityChange = (type) => {
        if (type === 'increase') {
            setQuantity(quantity + 1);
        } else if (type === 'decrease' && quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const addToCart = () => {
      if (!userId) {
        sessionStorage.setItem('isFromOrder', cake.id);
        navigate('/login');
        return;
    }
      const missingRequired = cake.variations
          .filter(v => v.choice_type === 0)  // Required variations
          .some(v => !selectedVariations[v.variation_type]);  // Check if any required is missing
  
      if (missingRequired) {
          alert('Please select all required options.');
          return;
      }
      if (!userId) {
        sessionStorage.setItem('isFromOrder', cake.id);
        navigate('/login');
        return;
    }    
      const formattedVariations = Object.entries(selectedVariations).map(([variationType, variationValueId]) => {
          const variationObj = cake.variations.find(v => v.variation_type === variationType);
  
          // Handle Multiple Selections (choice_type: 2) or Single Selections
          const selectedValues = Array.isArray(variationValueId)
              ? variationObj.variations.filter(v => variationValueId.includes(v.id))
              : [variationObj.variations.find(v => v.id === variationValueId)];
  
          return {
              id: variationObj.id,
              variationType,
              selectedVariationValues: selectedValues.map(selectedValue => ({
                  id: selectedValue.id,
                  variationValue: selectedValue.value,
                  price: selectedValue.price,
              }))
          };
      });
    
      dispatch({
          type: 'ADD_TO_CART',
          payload: {
              cake,
              selectedVariationtypes: formattedVariations,
              price: totalPrice,
              quantity,
              instructions,
          },
      });
  
      navigate('/cart');
  };
  
  

    const toggleFavorite = async () => {
        if (!userId) {
            navigate('/login');
            return;
        }
        const response = await storeFavProduct(cake.id, userId);
        if (response.message === 'ID added successfully') {
            setFavorites((prev) => [...prev, cake.id.toString()]);
        } else {
            setFavorites((prev) => prev.filter((id) => id !== cake.id.toString()));
        }
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: cake.name,
                    text: `Check out this cake: ${cake.name}`,
                    url: window.location.href ,
                });
            } catch (error) {
                console.log('Error sharing:', error);
            }
        } else {
            alert('Sharing is not supported in your browser.');
        }
    };

// Conditional rendering based on loading state and cake data
// Conditional rendering based on loading state and cake data
if (isLoading) {
    return (
      <div className="bg-gradient-to-b from-teal-100 to-teal-200 flex items-center justify-center">
        <div className="p-4 relative flex flex-col max-w-md m-1 md:m-4 w-full bg-white shadow-lg rounded-2xl gap-2">
          
          {/* Skeleton Image */}
          <div className="w-full h-80 bg-gray-300 rounded-lg animate-pulse mb-4"></div>
  
          {/* Skeleton Like and Share Buttons */}
          <div className="absolute z-10 top-6 right-5 flex space-x-3">
            <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
            <div className="w-10 h-10 bg-gray-300 rounded-full animate-pulse"></div>
          </div>
  
          {/* Skeleton Cake Name */}
          <div className="w-3/4 h-6 bg-gray-300 rounded animate-pulse mx-auto my-2"></div>
  
          {/* Skeleton Price and Quantity Section */}
          <div className='flex flex-wrap justify-between mb-4'>
            <div className="w-1/3 h-6 bg-gray-300 rounded animate-pulse"></div>
            
            {/* Skeleton Quantity Selector */}
            <div className="flex items-center space-x-4">
              <div className="w-10 h-8 bg-gray-300 rounded-md animate-pulse"></div>
              <div className="w-10 h-8 bg-gray-300 rounded-md animate-pulse"></div>
            </div>
          </div>
  
          {/* Skeleton Description */}
          <div className="h-16 w-full bg-gray-300 rounded animate-pulse"></div>
  
          {/* Skeleton Variations Section */}
          <div>
            <div className="w-3/4 h-4 bg-gray-300 rounded animate-pulse mb-2"></div>
            <div className="flex flex-wrap space-x-2">
              <div className="w-20 h-8 bg-gray-300 rounded-md animate-pulse"></div>
              <div className="w-20 h-8 bg-gray-300 rounded-md animate-pulse"></div>
            </div>
          </div>
  
          {/* Skeleton Special Instructions */}
          <div className="w-full h-12 bg-gray-300 rounded-md animate-pulse mt-4"></div>
  
          {/* Skeleton Action Buttons */}
          <div className="flex space-x-3 mt-3">
            <div className="w-full h-12 bg-gray-300 rounded-lg animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }
  
  
    return (
        <div className=" bg-gradient-to-b from-teal-100 to-teal-200 flex items-center justify-center">
            {cake ? (
                <div className="p-4 relative flex flex-col  max-w-md m-1 md:m-4 w-full bg-white shadow-lg rounded-2xl gap-2">
<Swiper
    modules={[Navigation, Pagination,Autoplay,EffectFade]}
    navigation
    pagination={{ clickable: true }}
    loop={true}
    autoplay={{ delay: 3000 }}  // Optional auto-slide
    spaceBetween={10}
    slidesPerView={1}
    zoom={true}
    effect="fade"
    className="slider-container w-full h-80 "
>
    {sliderImages.map((img, index) => (
        <SwiperSlide key={index} className='flex items-center'>
            <img 
                src={img} 
                alt="Cake Variation" 
                className="w-full h-auto object-cover "
            />
        </SwiperSlide>
    ))}
</Swiper>


  <div className="absolute z-10 bottom-5 right-2 flex space-x-3">
          {/* Like Button */}
          <div
            className="relative"
            onMouseEnter={() => setShowLikeTooltip(true)}
            onMouseLeave={() => setShowLikeTooltip(false)}
          >
            <div
              className="bg-white text-[#1c9d98] w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:scale-110 transition-transform shadow-md"
              onClick={toggleFavorite}
            >
              <FontAwesomeIcon
                icon={faHeart}
                className={`text-sm ${isFavorite ? 'text-red-500' : 'text-[#1c9d98]'}`}
              />
            </div>
            {showLikeTooltip && (
              <div className="absolute top-full right-0 mt-1 bg-gray-700 text-white text-xs py-1 px-2 rounded">
                {isFavorite ? 'Unlike' : 'Like'}
              </div>
            )}
          </div>
  
          {/* Share Button */}
          <div
            className="relative"
            onMouseEnter={() => setShowShareTooltip(true)}
            onMouseLeave={() => setShowShareTooltip(false)}
          >
            <div
              className="bg-white text-[#1c9d98] w-10 h-10 flex items-center justify-center rounded-full cursor-pointer hover:scale-110 transition-transform shadow-md"
              onClick={handleShare}
            >
 <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.47333 4.19375L7.33333 3.38125V8.75C7.33333 8.91575 7.4036 9.07475 7.5286 9.19194C7.6536 9.30912 7.8232 9.375 8 9.375C8.1768 9.375 8.3464 9.30912 8.4714 9.19194C8.5964 9.07475 8.66667 8.91575 8.66667 8.75V3.38125L9.52667 4.19375C9.58867 4.25233 9.6624 4.29882 9.7436 4.33056C9.82487 4.36229 9.912 4.37862 10 4.37862C10.088 4.37862 10.1751 4.36229 10.2564 4.33056C10.3376 4.29882 10.4113 4.25233 10.4733 4.19375C10.5358 4.13565 10.5854 4.06652 10.6193 3.99036C10.6531 3.9142 10.6705 3.83251 10.6705 3.75C10.6705 3.66749 10.6531 3.5858 10.6193 3.50964C10.5854 3.43347 10.5358 3.36435 10.4733 3.30625L8.47333 1.43125C8.41133 1.37267 8.3376 1.32617 8.2564 1.29444C8.17513 1.26271 8.088 1.24637 8 1.24637C7.912 1.24637 7.82487 1.26271 7.7436 1.29444C7.6624 1.32617 7.58867 1.37267 7.52667 1.43125L5.52667 3.30625C5.40113 3.42394 5.33061 3.58356 5.33061 3.75C5.33061 3.91644 5.40113 4.07606 5.52667 4.19375C5.65221 4.31144 5.82247 4.37756 6 4.37756C6.17753 4.37756 6.3478 4.31144 6.47333 4.19375Z" fill="#1C9D98"/>
<path d="M12 5.625H10V6.875H12V12.5H4V6.875H6V5.625H4C3.64638 5.625 3.30724 5.75669 3.05719 5.99112C2.80714 6.22554 2.66667 6.5435 2.66667 6.875V12.5C2.66667 12.8315 2.80714 13.1494 3.05719 13.3839C3.30724 13.6183 3.64638 13.75 4 13.75H12C12.3536 13.75 12.6927 13.6183 12.9428 13.3839C13.1929 13.1494 13.3333 12.8315 13.3333 12.5V6.875C13.3333 6.5435 13.1929 6.22554 12.9428 5.99112C12.6927 5.75669 12.3536 5.625 12 5.625Z" fill="#1C9D98"/>
</svg>


            </div>
            {showShareTooltip && (
              <div className="absolute top-full right-0 mt-1 bg-gray-700 text-white text-xs py-1 px-2 rounded">
                Share
              </div>
            )}
          </div>
        </div>

  
                    {/* Cake Name and Price */}
                    <h3 className=" font-semibold text-gray-900 text-center text-2xl">{cake.name}</h3>
                    
                    <div className='flex flex-wrap justify-between'>
                    <p className="text-md font-semibold  text-[#1c9d98] mt-1">
                        {parseFloat(totalPrice).toFixed(2)} QAR {/* Display updated price */}
                    </p>
               
                    {/* Quantity Selector */}
                    <div className="flex items-center space-x-4 ">
                        <button 
                            onClick={() => handleQuantityChange('decrease')} 
                            className="px-3 py-1 bg-red-500 text-white rounded-md">
                            -
                        </button>
                        <span className="text-lg font-medium">{quantity}</span>
                        <button 
                            onClick={() => handleQuantityChange('increase')} 
                            className="px-3 py-1 bg-[#1c9d98] text-white rounded-md">
                            +
                        </button>
                    </div>
                    </div>
    
                    {/* Description */}
                    <div
                        className="text-gray-700 text-xs overflow-hidden overflow-ellipsis"
                        style={{ display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}
                        dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                    />


                  
    {/* Variations Selection Section */}
    <div className="">
  {cake.variations
    .filter((_, index) => index !== 0)  // Exclude the first variation (default)
    .map((variation) => (
      <div key={variation.id}>
        <h5 className="font-medium text-gray-700 text-xs mt-2 mb-1">
          Choice of {variation.variation_type}
          {variation.choice_type === 0 && (
            <span className="text-red-500 text-[8px] ml-1">Required</span>  // Red * for required
          )}
          {variation.choice_type === 1 && (
            <span className="text-gray-500 text-[8px] ml-1">(Optional)</span>  // Optional text
          )}
          {variation.choice_type === 2 && (
            <span className="text-blue-500 text-[8px] ml-1">(Multiple)</span>  // Multiple text
          )}
        </h5>

        <div className="flex flex-wrap gap-2">
          {variation.variations.map((option) => {
            const isSelected = Array.isArray(selectedVariations[variation.variation_type])
              ? selectedVariations[variation.variation_type].includes(option.id)
              : selectedVariations[variation.variation_type] === option.id;

            return (
              <label
                key={option.id}
                className={`flex items-center cursor-pointer px-3 py-1 text-xs font-medium rounded-md transition duration-300 ${
                  isSelected
                    ? 'border-[#1a7b75] bg-[#e0f7f1] text-[#1a7b75]'
                    : 'border-[#ccc] bg-transparent text-[#333]'
                }`}
                style={{ borderWidth: '1px' }}
              >
                {/* Conditionally render radio button or checkbox */}
                {variation.choice_type === 0 ? (
                  <input
                    type="radio"
                    name={variation.variation_type}
                    value={option.id}
                    checked={isSelected}
                    onChange={() => handleVariationChange(
                      variation.variation_type,
                      option.id,
                      option.price,
                      option.image_url,
                      variation.choice_type
                    )}
                    className="mr-2"
                  />
                ) : (
                  <input
                    type="checkbox"
                    value={option.id}
                    checked={isSelected}
                    onChange={(e) => handleVariationChange(
                      variation.variation_type,
                      option.id,
                      option.price,
                      option.image_url,
                      variation.choice_type
                    )}
                    className={`mr-2 ${isSelected ? 'cursor-not-allowed' : ''}`}
                    // disabled={isSelected}  // Disable the checkbox if it's already selected
                  />
                )}
                <span>{option.value}</span>
              </label>
            );
          })}
        </div>
      </div>
    ))}
</div>



                    {/* Special Instructions */}
                    <div className="">
    <textarea
        className="w-full border border-gray-300 rounded-md p-2 h-12 resize-none overflow-hidden"
        placeholder="Special instructions (optional)"
        value={instructions}
        onChange={(e) => setInstructions(e.target.value)}
        rows="1"
    />
</div>


                    {/* Action Buttons */}
                    <div className="flex justify-center space-x-3 mt-3">
                        <button
                            className="flex w-auto justify-center items-center whitespace-nowrap border-2 border-[#1c9d98] bg-[#dcf5f4] text-[#1c9d98] px-4 py-2 rounded-lg hover:bg-[#1c9d98] hover:text-white transition duration-300 shadow-sm"
                            onClick={addToCart}
                        >
                            <FontAwesomeIcon icon={faShoppingCart} className="mr-2 text-sm" />
                            Add to Cart
                        </button>
                        {/* <button
                            className="flex w-full items-center whitespace-nowrap bg-[#1c9d98] text-white px-4 py-2 rounded-lg hover:bg-[#1a7b75] transition duration-300 shadow-sm"
                        >
                            <FontAwesomeIcon icon={faShoppingBasket} className="mr-2 text-sm" />
                            Buy Now
                        </button> */}
                    </div>


                </div>
            ) : (
                <p className="text-center text-2xl text-red-500">No cake selected.</p>
            )}
        </div>
    );
};

export default ProductOrder;
