import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // If using react-router for navigation

const CakeSlider = ({ products }) => {
  const navigate = useNavigate();
  const extendedProducts = [...products, products[0]]; // Duplicate the first product and add it to the end
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [transitionDuration, setTransitionDuration] = useState(1500); // Default transition duration


  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimating) {
        nextSlide();
      }
    }, transitionDuration); // Auto slide every specific duration

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }); // Dependency includes transitionDuration

  const nextSlide = () => {
    if (isAnimating) return;

    // Set random transition duration between 1 and 3 seconds for each slide
    setTransitionDuration(Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000);
    setIsAnimating(true); // Set animation flag to true

    setCurrentIndex((prevIndex) => {
      if (prevIndex === extendedProducts.length - 1) {
        // If it's the last slide (duplicate of first), we make it hide quickly
        setTransitionDuration(100); // Quick hide for the last slide
        setIsAnimating(false); // Disable animation
        return 0; // Jump to the first slide
      } else {
        return (prevIndex + 1) % extendedProducts.length; // Regular transition to next slide
      }
    });
  };

  const prevSlide = () => {
    if (isAnimating) return;

    // Set random transition duration between 1 and 3 seconds for each slide
    setTransitionDuration(Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000);
    setIsAnimating(true); // Set animation flag to true

    setCurrentIndex((prevIndex) => {
      return (prevIndex - 1 + extendedProducts.length) % extendedProducts.length;
    });
  };

  const handleTransitionEnd = () => {
    setIsAnimating(false); // Reset isAnimating after the transition is complete
  };

  // Function to handle image or overlay click for redirect
  const handleRedirect = (id) => {
    navigate(`/order?id=${id}`); // Redirect to the order page with the product id
  };

  return (
    <div className="relative w-full h-[500px] overflow-hidden rounded-md">
      <div
        className="absolute inset-0 flex transition-transform ease-in-out"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
          transitionDuration: `${isAnimating ? transitionDuration : 0}ms`, // Set dynamic transition duration, 0ms for no animation
        }}
        onTransitionEnd={handleTransitionEnd} // Handle when the transition is done
      >
        {extendedProducts.map((product, index) => (
          <div key={index} className="w-full flex-none relative group cursor-pointer"   onClick={() => handleRedirect(product.id)}>
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform group-hover:scale-105"
            />
            <div
              className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 text-center backdrop-blur-lg group-hover:bg-[#1c9d98] group-hover:text-white transition-colors"
             // Redirect on click
            >
              <p className="font-semibold">{product.name}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Arrows */}
      <div
        className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white cursor-pointer opacity-75 hover:opacity-100 transition-opacity"
        onClick={prevSlide}
      >
        <span className="text-3xl">&#10094;</span>
      </div>
      <div
        className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white cursor-pointer opacity-75 hover:opacity-100 transition-opacity"
        onClick={nextSlide}
      >
        <span className="text-3xl">&#10095;</span>
      </div>
    </div>
  );
};

export default CakeSlider;
