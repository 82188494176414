import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import {
  faHeart,
  faHistory,
  faSignOutAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const CustomerProfileDropdown = ({ userName, onLogout, page, onClose }) => {
  const dropdownRef = useRef(null);

  // Close dropdown if click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose(); // Call onClose to hide the dropdown
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={dropdownRef}
      className={`absolute ${
        page === "bottom" ? "bottom-16 right-0" : "right-0 mt-2"
      } w-48 bg-gradient-to-r from-[#e0f7fa] to-[#b2ebf2] text-[#1c9d98] shadow-lg rounded-lg z-50 overflow-hidden transition-all duration-300`}
    >
      <div className="p-4 border-b">
        <h4 className="text-lg font-semibold text-center">{userName}</h4>
      </div>
      <div className="p-2">
        <ul>
        <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <Link to="/info" className="flex items-center w-full">
              <FontAwesomeIcon icon={faUser} className="mr-2" />{" "}
              <span>Personal Info</span>
            </Link>
          </li>
          <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <Link to="/delivery" className="flex items-center w-full">
              <FontAwesomeIcon icon={faUser} className="mr-2" />{" "}
              <span>Delivery Address</span>
            </Link>
          </li>
          <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <Link to="/favorites" className="flex items-center w-full">
              <FontAwesomeIcon icon={faHeart} className="mr-2" />{" "}
              <span>Favorite</span>
            </Link>
          </li>
          <li className="flex items-center p-2 hover:bg-[#b2ebf2] cursor-pointer transition duration-200 hover:rounded-lg">
            <Link to="/orderhistory" className="flex items-center w-full">
              <FontAwesomeIcon icon={faHistory} className="mr-2" />{" "}
              <span>Order History</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="p-2 border-t">
        <button
          onClick={onLogout}
          className="flex items-center w-full text-left text-red-600 hover:bg-red-100 rounded-md p-2 transition duration-200 hover:rounded-lg"
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
          <span>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default CustomerProfileDropdown;
