// ../Apis/StoreFavProduct.js
import axios from 'axios';

export const storeFavProduct = async (productId,userId) => {
  const token = sessionStorage.getItem('access_token'); // Retrieve the access token
  try {
    // Create a new FormData instance
    const formData = new FormData();
    formData.append('id_to_store', productId); // Append the product ID to the form data

    const response = await axios.post(
      `https://pandapanel.counterbill.com/connector/api/store-fav-product/${userId}`,
      formData, // Send the FormData instance
      {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token
          Accept: 'application/json',
          // 'Content-Type' is not necessary when using FormData, axios sets it automatically
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error storing favorite product:', error);
    throw error;
  }
};
