import React, { createContext, useReducer, useContext, useEffect, useState } from 'react';
import { storeCart } from '../Apis/StoreCart'; // Import the storeCart API
import { showCart } from '../Apis/ShowCart'; // Import the showCart API

const CartContext = createContext();

const initialState = {
  items: [], // Initialize items as an empty array
};

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART': {
      const itemsArray = state.items || []; // Ensure items is an array
      const { cake, selectedVariationtypes, price, quantity = 1, instructions } = action.payload;
      console.log(cake);
    
      let productId, name, image, default_price, unit_id, variation = [];
    
      // If cake exists in payload (new addition), extract properties
      if (cake) {
        ({ id: productId, name, image, default_price, unit_id } = cake);
        variation = selectedVariationtypes;
      } else {
        // If cake is undefined (increase quantity case), find the existing item
        const existingItem = itemsArray.find(item => item.cartId === action.payload.id);
    
        if (!existingItem) return state; // If item isn't found, return the state as-is
        productId = existingItem.id;
        name = existingItem.name;
        image = existingItem.image_url;
        unit_id = existingItem.unit_id; // Retrieve unit_id from existing item
        default_price = existingItem.unit_price;
        variation = existingItem.variation;
      }
    
      // Fetch the customerId from sessionStorage
      const customerId = sessionStorage.getItem('customer_id');
      const uniqueNumber = Date.now();
      const cartId = `${customerId}-${productId}-${uniqueNumber}`; // cartId = customerId-productId-uniqueNumber
    
      // Check if the item already exists in the cart
      const existingItemIndex = itemsArray.findIndex(
        (item) => item.id === productId && JSON.stringify(item.variation) === JSON.stringify(variation)
      );
    
      let updatedItems;
    
      if (existingItemIndex >= 0) {
        // Update the existing item if variations are the same
        updatedItems = itemsArray.map((item, index) => {
          if (index === existingItemIndex) {
            return { 
              ...item, 
              quantity: item.quantity + quantity // Increment quantity
            };
          }
          return item;
        });
      } else {
        // If item doesn't exist, add it to the cart
        updatedItems = [
          ...itemsArray,
          {
            id: productId,
            name,
            price,
            image_url: image,
            unit_price: default_price,
            variation,
            quantity,
            instructions,
            cartId, // Add the cartId for this item
            unit_id, // Add the unit_id to the cart item
          },
        ];
      }
    
      return { ...state, items: updatedItems };
    }
    

    case 'REMOVE_FROM_CART': {
      const itemsArray = state.items || []; // Ensure items is an array
      const existingItemIndex = itemsArray.findIndex(item => item.cartId === action.payload.id);
      let updatedItems = [...itemsArray];

      if (existingItemIndex >= 0) {
        const item = updatedItems[existingItemIndex];

        if (action.payload.removeAll) {
          // Remove the item completely
          updatedItems.splice(existingItemIndex, 1);
        } else if (item.quantity > 1) {
          // If quantity > 1, decrement the quantity
          updatedItems[existingItemIndex] = { ...item, quantity: item.quantity - 1 };
        } else {
          // If quantity is 1, remove the item
          updatedItems.splice(existingItemIndex, 1);
        }
      }

      return { ...state, items: updatedItems };
    }

    case 'CLEAR_CART': {
      return { ...state, items: [] }; // Empty the cart by setting items to an empty array
    }

    case 'SET_CART_ITEMS': {
      return { ...state, items: action.payload || [] }; // Ensure payload is an array
    }

    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [cartInitialized, setCartInitialized] = useState(false); // Add flag for initial cart load
 
  const userId = sessionStorage.getItem('customer_id');
  useEffect(() => {
    if (userId) {
      fetchCartItems(userId); // This runs when the component mounts or when userId changes
    }
  }, [userId]);

  // Create a function to fetch cart items manually
  const fetchCartItems = async (userId) => {
    try {
      const cartItems = await showCart(userId);
      dispatch({ type: 'SET_CART_ITEMS', payload: cartItems });
      setCartInitialized(true);
    } catch (error) {
      console.error('Error fetching initial cart items:', error);
    }
  };

  useEffect(() => {
    const updateCart = async () => {
      try {
        const cartEncoded = JSON.stringify(state.items || []); // Fallback to empty array
        await storeCart(userId, cartEncoded);
      } catch (error) {
        console.error('Error storing cart:', error);
      }
    };

    if (cartInitialized) { // Only run if the cart has been initialized
      updateCart();
    }
  }, [state.items, userId, cartInitialized]);

  return (
    <CartContext.Provider value={{ state, dispatch,fetchCartItems  }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
