import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { AuthProvider } from './context/AuthContext';
import { FavoritesProvider } from './context/FavoritesContext';
import Header from './components/Header';
import Footer from './components/Footer';
import BottomNav from './components/BottomNav';
import Home from './pages/Home';
import Cart from './pages/Cart';
import Lookup from './pages/Lookup';
import Login from './pages/Login';
import More from './pages/More';
import UserFavorite from './pages/UserFavorite';
import OrderHistory from './pages/OrderHistory';
import ProtectedRoute from './components/ProtectedRoute';
import Checkout from './pages/Checkout';
import ProductOrder from './pages/ProductOrder';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './queryClient'; // Import the queryClient
import Info from './pages/info';
import Search from './pages/Search';
import Delivery from './pages/Delivery';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const [isCategoryBack, setIsCategoryBack] = useState(false);
  const userId = sessionStorage.getItem('customer_id');

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CartProvider userId={userId}>
          <FavoritesProvider>
            <Router>
              <ScrollToTop /> {/* Ensure this is directly inside Router */}
              <Header />
              
              <Routes>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Home isCategoryBack={isCategoryBack} setIsCategoryBack={setIsCategoryBack} />
                    </ProtectedRoute>
                  }
                />
                <Route path="/cart" element={<ProtectedRoute><Cart /></ProtectedRoute>} />
                <Route
                  path="/category"
                  element={
                    <ProtectedRoute>
                      <Home isCategoryBack={isCategoryBack} setIsCategoryBack={setIsCategoryBack} />
                    </ProtectedRoute>
                  }
                />
                <Route path="/lookup" element={<ProtectedRoute><Lookup /></ProtectedRoute>} />
                <Route path="/login" element={<Login />} />
                <Route path="/info" element={<ProtectedRoute><Info /></ProtectedRoute>} />
                <Route path="/delivery" element={<ProtectedRoute><Delivery /></ProtectedRoute>} />
                <Route path="/more" element={<ProtectedRoute><More /></ProtectedRoute>} />
                <Route path="/order" element={<ProtectedRoute><ProductOrder /></ProtectedRoute>} />
                <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
                <Route path="/favorites" element={<ProtectedRoute><UserFavorite /></ProtectedRoute>} />
                <Route path="/orderhistory" element={<ProtectedRoute><OrderHistory /></ProtectedRoute>} />
                <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
              </Routes>

              <BottomNav setIsCategoryBack={setIsCategoryBack} isCategoryBack={isCategoryBack} />

              <ToastContainer
                position="bottom-center"
                autoClose={500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />

              <Footer />
            </Router>
          </FavoritesProvider>
        </CartProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
