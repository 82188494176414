import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getDeliveryAddress } from '../Apis/GetDelivery';
import { storeDeliveryAddress } from '../Apis/StoreDelivery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faPaperPlane, faTimes, faMapLocation } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

function Delivery() {
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState({ type: '', zone: '', street: '', building: '', details: '' });
  const [editingIndex, setEditingIndex] = useState(null); // Track the index of the address being edited
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

  const userId = sessionStorage.getItem('customer_id');  // Get user ID from sessionStorage
  const token = sessionStorage.getItem('access_token');  // Get access token for API auth

  // Fetch existing delivery address when component mounts
  useEffect(() => {
    fetchAddresses();
  }, []);

  const fetchAddresses = async () => {
    try {
      const response = await getDeliveryAddress(userId, token);

      if (response.delivery_address) {
        const parsedAddress = JSON.parse(response.delivery_address);
        setAddresses(Array.isArray(parsedAddress) ? parsedAddress : [parsedAddress]);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
      Swal.fire('Error', 'Failed to load delivery address.', 'error');
    }
  };

  const handleMapLocation = async (zone, street, building) => {
    try {
        // Make the GET request to the API endpoint
        const response = await axios.get(`https://qnas.qa/get_location/${zone}/${street}/${building}`);

        if (response.data.length > 0) {
            // Extract the coordinates from the response
            const { coord_x, coord_y } = response.data[0];

            // Redirect to Google Maps with the coordinates
            const googleMapsUrl = `https://www.google.com/maps?q=${coord_y},${coord_x}`;
            window.open(googleMapsUrl, '_blank');
        } else {
            // If no data found, show an error message
            Swal.fire({
                icon: 'error',
                title: 'Location not found',
                text: 'The requested location could not be found.',
            });
        }
    } catch (error) {
        // Handle errors like network issues, rate limiting, etc.
        console.error('Error fetching location:', error);

        if (error.response) {
            // If there is a response from the API but it's an error
            Swal.fire({
                icon: 'error',
                title: 'API Error',
                text: `Error: ${error.response.data.message || 'An unexpected error occurred'}`,
            });
        } else if (error.request) {
            // If no response was received (e.g., network error)
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Failed to connect to the server. Please check your connection or try again later.',
            });
        } else {
            // Something went wrong in setting up the request
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `Something went wrong: ${error.message}`,
            });
        }
    }
};

  const handleAddOrUpdateAddress = async () => {
    if (newAddress.type && newAddress.zone && newAddress.street && newAddress.building) {
      const newAddressType = newAddress.type?.toLowerCase() || '';
      const existingIndex = addresses.findIndex(address => address.type?.toLowerCase() === newAddressType);

      if (existingIndex !== -1 && newAddressType === 'home') {
        Swal.fire({
          title: 'Change Home Address?',
          text: 'Your existing home address will be replaced. Do you want to continue?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1c9d98',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, update it'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await updateAddress(existingIndex);
          }
        });
      } else if (existingIndex !== -1) {
        await updateAddress(existingIndex);
      } else {
        await storeNewAddress();
      }
    }
  };

  const storeNewAddress = async () => {
    try {
      const updatedAddresses = [...addresses, newAddress];
      const response = await storeDeliveryAddress(userId, updatedAddresses, token);

      if (response.message === "success") {
        Swal.fire('Saved!', 'Your delivery address has been saved.', 'success');
        fetchAddresses();
        closeModal();
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to save address.', 'error');
    }
  };

  const updateAddress = async (index) => {
    try {
      const updatedAddresses = [...addresses];
      updatedAddresses[index] = { ...updatedAddresses[index], ...newAddress };

      const response = await storeDeliveryAddress(userId, updatedAddresses, token);

      if (response.message === "success") {
        setAddresses(updatedAddresses);
        closeModal();
        Swal.fire('Updated!', `${newAddress.type} address has been updated.`, 'success');
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to update address.', 'error');
    }
  };

  const resetForm = () => {
    setNewAddress({ type: '', zone: '', street: '', building: '', details: '' });
    setEditingIndex(null);
  };

  const handleDelete = async (type) => {
    const filteredAddresses = addresses.filter(address => address.type !== type);

    Swal.fire({
      title: 'Are you sure?',
      text: 'This address will be deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await storeDeliveryAddress(userId, filteredAddresses, token);
          if (response.message === "success") {
            Swal.fire('Deleted!', 'The address has been deleted.', 'success');
            setAddresses(filteredAddresses);
          }
        } catch (error) {
          Swal.fire('Error', 'Failed to delete address.', 'error');
        }
      }
    });
  };

  const handleEdit = (index) => {
    const addressToEdit = addresses[index];
    setNewAddress(addressToEdit);
    setEditingIndex(index);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-8">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg sm:max-w-xl md:max-w-2xl">
        {/* Address Header with + Add Button */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold text-[#1c9d98]">Addresses</h2>
          <button
            onClick={openModal}
            className="text-[#1c9d98] hover:bg-[#1c9d98] hover:text-white transition-colors duration-300 py-2 px-4 rounded-lg border border-[#1c9d98] flex items-center"
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className="ml-2">Add Address</span>
          </button>
        </div>
  
        <div className="space-y-4">
          {addresses.map((address, index) => (
            <div key={index} className="p-5 border border-gray-200 rounded-lg flex flex-col sm:flex-row justify-between items-start sm:items-center bg-white shadow-md hover:shadow-lg transition-shadow duration-300">
              <div className="mb-4 sm:mb-0">
                <h3 className="text-lg font-semibold text-[#1c9d98]">{address.type}</h3>
                <p className="text-sm text-gray-600 mt-1">
                  <span className="font-medium">Zone:</span> {address.zone}, <span className="font-medium">Street:</span> {address.street}, <span className="font-medium">Building:</span> {address.building}
                </p>
                <p className="text-xs text-gray-500 mt-1">{address.details}</p>
              </div>
              <div className="flex space-x-3">
              <button
                  onClick={() => handleMapLocation(address.zone,address.street,address.building)}
                  className="text-blue-500 hover:text-white hover:bg-blue-500 transition-colors duration-200 px-3 py-2 rounded-full"
                >
                  <FontAwesomeIcon icon={faMapLocation} />
                </button>
                <button
                  onClick={() => handleEdit(index)}
                  className="text-[#1c9d98] hover:text-white hover:bg-[#1c9d98] transition-colors duration-200 px-3 py-2 rounded-full"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={() => handleDelete(address.type)}
                  className="text-red-500 hover:text-white hover:bg-red-500 transition-colors duration-200 px-3 py-2 rounded-full"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
        </div>
  
        {/* Modal for adding/editing address */}
        {isModalOpen && (
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center"
            onClick={closeModal} // Close modal when clicking outside the modal
          >
            <div
              className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md"
              onClick={(e) => e.stopPropagation()} // Prevent the modal from closing when clicked inside
            >
              <h2 className="text-xl font-semibold text-[#1c9d98] mb-4">
                {editingIndex !== null ? "Edit Address" : "Add Address"}
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input
  type="text"
  list="address-types"
  placeholder="Address Type (Home, Office, etc.)"
  value={newAddress.type}
  onChange={(e) => setNewAddress({ ...newAddress, type: e.target.value })}
  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition-all"
/>
<datalist id="address-types">
  <option value="Home" />
  <option value="Office" />
  <option value="Other" />
  <option value="Billing" />
  <option value="Shipping" />
</datalist>

                <input
                  type="text"
                  placeholder="Zone Number (0 - 98)"
                  value={newAddress.zone}
                  onChange={(e) => setNewAddress({ ...newAddress, zone: e.target.value })}
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition-all"
                />
                <input
                  type="text"
                  placeholder="Street Name or Number"
                  value={newAddress.street}
                  onChange={(e) => setNewAddress({ ...newAddress, street: e.target.value })}
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition-all"
                />
                <input
                  type="text"
                  placeholder="Building Number"
                  value={newAddress.building}
                  onChange={(e) => setNewAddress({ ...newAddress, building: e.target.value })}
                  className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition-all"
                />
                <input
                  type="text"
                  placeholder="Additional Details (optional)"
                  value={newAddress.details}
                  onChange={(e) => setNewAddress({ ...newAddress, details: e.target.value })}
                  className="p-3 border border-gray-300 rounded-lg sm:col-span-2 focus:outline-none focus:ring-2 focus:ring-[#1c9d98] transition-all"
                />
              </div>
              <div className="flex space-x-4 mt-6">
                <button
                  onClick={handleAddOrUpdateAddress}
                  className="w-full bg-[#1c9d98] text-white py-3 rounded-lg hover:bg-[#178f8b] transition-colors duration-300 flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                  Submit
                </button>
                <button
                  onClick={closeModal}
                  className="w-full text-gray-500 py-3 rounded-lg hover:bg-gray-200 transition-colors duration-300 flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
  
}

export default Delivery;
