import React, { useState, useEffect } from "react";
import CategoryList from "../components/CategoryList";
import CakeCard from "../components/CakeCard";
import { useQuery } from "@tanstack/react-query"; // Import React Query hooks
import getCategoryList from "../Apis/getCategoryList";
import getProductList from "../Apis/getProductList";
import getTrending from "../Apis/getTrending";
import getFavourite from "../Apis/getFavourite";
import CakeSlider from "../components/CakeSlider";

// Skeleton Loader for Category Item
const CategoryItemSkeleton = () => {
  return (
    <li
      className="relative flex items-center justify-center text-center p-4 rounded-lg bg-gray-200 animate-pulse"
      style={{
        maxWidth: "250px",
        maxHeight: "250px",
        minWidth: "120px",
        minHeight: "100px",
        overflow: "hidden",
      }}
    >
      {/* Simulate Image Section */}
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-300"></div>

      {/* Simulate Text Overlay */}
      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-gray-400 to-transparent">
        <div className="h-6 bg-gray-400 rounded w-3/4 mx-auto"></div>
      </div>
    </li>
  );
};

const Home = ({ isCategoryBack, setIsCategoryBack }) => {
  const [categoryDatas, setCategoryDatas] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryList, setShowCategoryList] = useState(false); // State to toggle category list visibility
  const [loadingCategoryProducts, setLoadingCategoryProducts] = useState(false);

  // Fetch categories with React Query (Updated for v5)
  const {
    data: categoriesData,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoryList,
  });

  // Fetch trending products with React Query (Updated for v5)
  const {
    data: trendingProducts,
    isLoading: trendingLoading,
    error: trendingError,
  } = useQuery({
    queryKey: ["trending"],
    queryFn: getTrending,
  });

  // Fetch favorite products with React Query (Updated for v5)
  const {
    data: favoriteProducts,
    isLoading: favoritesLoading,
    error: favoritesError,
  } = useQuery({
    queryKey: ["favorites"],
    queryFn: getFavourite,
  });

  // Fetch recent cakes with React Query (Updated for v5)
  const {
    data: recentCakes,
    isLoading: recentCakesLoading,
    error: recentCakesError,
  } = useQuery({
    queryKey: ["recentCakes"],
    queryFn: () =>
      getProductList({ per_page: "5", wrapCategory: "Recent Cakes" }),
  });

  useEffect(() => {
    if (categoriesData && Array.isArray(categoriesData.data)) {
      const uniqueCategories = new Map();
      categoriesData.data.forEach((category) => {
        const categoryName = category.name;
        const categoryId = category.id;
        const categoryImage = category.image_url;

        if (!uniqueCategories.has(categoryName)) {
          uniqueCategories.set(categoryName, {
            id: categoryId,
            name: categoryName,
            image: categoryImage
              ? "https://pandapanel.counterbill.com/" + categoryImage
              : "https://pandapanel.counterbill.com/img/default.png",
          });
        }
      });
      const formattedCategories = Array.from(uniqueCategories.values());
      setCategoryDatas(formattedCategories);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (trendingProducts) {
      setCategoryProducts((prevProducts) => ({
        ...prevProducts,
        ...trendingProducts,
      }));
    }
  }, [trendingProducts]);

  useEffect(() => {
    if (favoriteProducts) {
      setCategoryProducts((prevProducts) => ({
        ...prevProducts,
        ...favoriteProducts,
      }));
    }
  }, [favoriteProducts]);

  useEffect(() => {
    if (recentCakes) {
      setCategoryProducts((prevProducts) => ({
        ...prevProducts,
        ...recentCakes,
      }));
    }
  }, [recentCakes]);

  // Fetch products based on category ID
  const handleCategorySelect = async (category_id, categoryName) => {
    setIsCategoryBack(false);
    if (selectedCategory === categoryName) return;

    setSelectedCategory(categoryName);
    setLoadingCategoryProducts(true); // Set loading state to true
    setShowCategoryList(false);

    try {
      const productsByCategory = await getProductList({ category_id });
      setCategoryProducts(productsByCategory);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoadingCategoryProducts(false); // Set loading state to false when done
    }
  };

  useEffect(() => {
    if (isCategoryBack) {
      setShowCategoryList(true); // Show category list
    } else {
      setShowCategoryList(false); // Hide category list
    }
  }, [isCategoryBack]);

  // Render loading skeletons
  if (
    categoriesLoading ||
    trendingLoading ||
    favoritesLoading ||
    recentCakesLoading
  ) {
    return (
      <div className="md:flex min-h-screen ">
        <div
          className={`fixed top-0  md:w-1/4 w-full z-10 h-screen md:p-4 bg-[#f9f9f9]  border-r overflow-y-auto scrollbar ${
            showCategoryList ? "block" : "hidden md:block"
          }`}
        >
          <div className="pt-20 px-2">
            <h2 className="text-xl font-bold mb-6 text-center">Categories</h2>
            <ul className="grid grid-cols-2  gap-2 justify-center">
              {Array.from({ length: 6 }).map((_, index) => (
                <CategoryItemSkeleton key={index} />
              ))}
            </ul>
          </div>
        </div>
        <div className="flex-grow md:ml-[25%] md:p-4 pt-2 overflow-y-auto min-h-screen">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-0 md:gap-6">
            {Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="flex gap-10 bg-white m-3 rounded-sm shadow-md p-4 animate-pulse"
              >
                <div className="flex flex-col flex-1 pr-4 justify-center">
                  {/* Skeleton Title */}
                  <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                  {/* Skeleton Description */}
                  <div className="h-4 bg-gray-200 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded mb-2"></div>
                  {/* Skeleton Button */}
                  <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
                </div>
                <div className="relative flex flex-col justify-center items-center">
                  {/* Skeleton Image */}
                  <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Render error states
  if (categoriesError || trendingError || favoritesError || recentCakesError) {
    return <p className="text-center text-red-500">Error fetching data!</p>;
  }

  const specialCategories = [
    "Most Trending Cakes",
    "Most Favourite Cakes",
    "Recent Cakes",
  ];


  return (
    <div className="md:flex min-h-screen">
      {/* Category list on large screens */}
      <div
        className={`fixed top-0 md:w-1/4 w-full z-10 h-screen md:p-4 bg-[#f9f9f9] border-r overflow-y-auto scrollbar ${
          showCategoryList ? "block" : "hidden md:block"
        }`}
      >
        <CategoryList
          categories={categoryDatas}
          onCategorySelect={handleCategorySelect}
        />
      </div>

      {/* Main content area */}
      <div className="flex flex-wrap gap-2 w-full md:ml-[25%] md:p-4 pt-2 overflow-y-auto min-h-screen">
        {Object.keys(categoryProducts).map((category) => (
          <div key={category} className="flex-1 mb-2 min-w-[200px] mx-3">
            {/* Category Name */}
            <h2 className="text-2xl font-bold text-center md:mb-4">
              {loadingCategoryProducts ? (
                <div className="h-8 bg-gray-200 rounded w-full mb-4"></div>
              ) : (
                category
              )}
            </h2>

            {/* Products Grid or Slider */}
            <div className={specialCategories.includes(category) ? ("Hi"):("grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-0 md:gap-6")}>
              {loadingCategoryProducts ? (
                <>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <div
                      key={index}
                      className="flex gap-10 bg-white m-3 rounded-sm shadow-md p-4 animate-pulse"
                    >
                      <div className="flex flex-col flex-1 pr-4 justify-center">
                        <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                        <div className="h-4 bg-gray-200 rounded mb-2"></div>
                        <div className="h-4 bg-gray-200 rounded mb-2"></div>
                        <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
                      </div>
                      <div className="relative flex flex-col justify-center items-center">
                        <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                 {specialCategories.includes(category) ? (

                
               <CakeSlider  products={categoryProducts[category]} />

)  : (
                    categoryProducts[category].map((product) => (
                      <CakeCard key={product.id} cake={product} />
                    ))
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
