import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-800 text-white text-center  mb-20 md:mb-0">
      <p>&copy; 2024 Cake Shop. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
