//BottomNav.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faUser, faEllipsisH, faListAlt } from '@fortawesome/free-solid-svg-icons';
import CustomerProfileDropdown from './CustomerProfileDropdown';
import { useCart } from '../context/CartContext';

const BottomNav = ({ setIsCategoryBack }) => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(location.pathname);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('down');

  const { state } = useCart();

  useEffect(() => {
    setActiveMenu(location.pathname);
    const customerId = sessionStorage.getItem('customer_id');
    const firstName = sessionStorage.getItem('name');

    if (customerId && firstName) {
      setIsLoggedIn(true);
      setUserName(firstName);
    } else {
      setIsLoggedIn(false);
    }
  }, [location.pathname]);

  // Scroll handling for hiding/showing the bottom nav
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setScrollDirection('down');  // Show Bottom Nav on Scroll Down
      } else {
        setScrollDirection('up');    // Hide Bottom Nav on Scroll Up
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleLogout = () => {
    sessionStorage.removeItem('customer_id');
    sessionStorage.removeItem('name');
    setIsLoggedIn(false);
    setShowDropdown(false);
  };

  const menuItems = [
    { to: "/", icon: faHome, label: "Menu" ,onClick: () => setIsCategoryBack(false)},
    { to: "/category", icon: faListAlt, label: "Category", onClick: () => setIsCategoryBack(true) },
    { to: "/cart", icon: faShoppingCart, label: "Cart" },
    { to: "/more", icon: faEllipsisH, label: "More" }
  ];

  return (
    <div 
      className={`md:hidden fixed bottom-0 left-0 right-0 bg-[#1c9d98] p-2 flex justify-around shadow-lg z-50 transition-transform duration-300 
                  ${scrollDirection === 'up' ? 'translate-y-full' : 'translate-y-0'}`}
    >
      {menuItems.map(({ to, icon, label, onClick }) => (
        <Link 
          key={label}
          to={to || "#"}
          onClick={onClick}
          className={`flex flex-col items-center transition-all ease-in duration-300 transform 
                      ${activeMenu === to ? 'bg-white text-[#1c9d98] border-2 border-[#1c9d98]' : 'hover:bg-white hover:text-[#1c9d98] text-white'} 
                      rounded-full w-16 h-16 justify-center relative`}
        >
          <FontAwesomeIcon icon={icon} className="text-2xl" />
          <span className="text-xs">{label}</span>
          {to === "/cart" && state.items.length > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full text-xs px-1">
              {state.items.length}
            </span>
          )}
        </Link>
      ))}
      {isLoggedIn ? (
        <div className="relative">
          <span 
            onClick={() => setShowDropdown(!showDropdown)}
            className="bg-white text-[#1c9d98] rounded-full w-10 h-10 flex items-center justify-center font-bold cursor-pointer"
          >
            {userName.charAt(0)}
          </span>
          {showDropdown && (
            <CustomerProfileDropdown userName={userName} onLogout={handleLogout} page={"bottom"} onClose={() => setShowDropdown(false)} />
          )}
        </div>
      ) : (
        <Link 
          to="/login"
          className="flex flex-col items-center transition-all ease-in duration-300 transform hover:bg-white hover:text-[#1c9d98] text-white rounded-full w-16 h-16 justify-center"
        >
          <FontAwesomeIcon icon={faUser} className="text-2xl" />
          <span className="text-xs">Login</span>
        </Link>
      )}
    </div>
  );
};

export default BottomNav;
